<template lang="">
  <div>
    <h1
      class="mb-6 lg:mb-8 text-gray-800 font-bold text-2xl md:text-3xl lg:text-4xl"
    >
      Ashley Bot 🤖
    </h1>
    <p class="text-base text-gray-800 mb-5">
      Check the underwriting eligibility of a customer’s vehicle. Selecting
      different <strong>products</strong> will run against their respective
      underwriting guidelines.
    </p>
    <div class="bg-white w-full rounded-lg p-6">
      <form @submit.prevent="submitForm">
        <div class="flex flex-row gap-x-5">
          <div class="w-1/4">
            <p class="font-bold text-sm text-gray-800">Product</p>
            <select
              v-model="inputs.product"
              class="w-full flex-grow px-4 py-2 md:p-4 border border-gray-300 text-sm md:text-base bg-white hover:bg-gray-100"
              required
            >
              <option :value="''">Select Product</option>
              <option value="ContinuousCoverage">Continuous Coverage</option>
              <option value="ORP">Off Rent</option>
              <option value="SLI">Supplemental Liability Insurance</option>
            </select>
          </div>
          <div class="w-1/4">
            <p class="font-bold text-sm text-gray-800">Vin</p>
            <input
              class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
              type="text"
              v-model="inputs.vin"
              placeholder="Enter Vin"
              required
            />
          </div>
          <div class="w-1/4">
            <p class="font-bold text-sm text-gray-800">
              Vehicle Registration State
            </p>
            <state-select
              v-model="inputs.state"
              required
              :states="vehicleRegistrationStates"
              class="w-full px-4 py-2 md:p-4 border text-sm md:text-base border-gray-300 bg-white hover:bg-gray-100"
            />
          </div>
          <div class="w-1/4">
            <p class="font-bold text-sm text-gray-800">Mileage</p>
            <select
              v-model="inputs.mileage"
              class="w-full flex-grow px-4 py-2 md:p-4 border border-gray-300 text-sm md:text-base bg-white hover:bg-gray-100"
              required
              placeholder="Milage"
            >
              <option :value="''">Select Mileage Range</option>
              <option
                v-for="milage in mileageRangeOptions"
                :value="milage.value"
                :key="milage"
              >
                {{ milage.label }}
              </option>
            </select>
          </div>
        </div>
        <button
          :disabled="disableSubmit"
          type="submit"
          class="mt-8 w-full py-2 md:py-4 text-white rounded-[60px]"
          :class="{
            'bg-gray-200 cursor-default text-gray-700': disableSubmit,
            'bg-lula-gradient hover:bg-lula-gradient-alt font-bold':
              !disableSubmit,
          }"
        >
          {{ checkEligibilityMessage.content }}
        </button>
      </form>
    </div>
    <div
      v-if="insuranceCriteriaStatus != '' || ineligibilityReasons.length > 0"
      class="bg-white w-full rounded-lg p-6 mt-10"
    >
      <div class="flex flex-row items-center">
        <div
          v-if="insuranceCriteriaStatus != ''"
          class="flex flex-row items-center"
        >
          <h1 class="text-gray-800 font-bold text-2xl md:text-3xl lg:text-4xl">
            Eligibility Report
          </h1>
          <p class="text-base ml-10 text-gray-800 font-bold">
            This vehicle is&nbsp;
          </p>
        </div>
        <span
          class="font-bold underline"
          :class="{
            'text-green-500': insuranceCriteriaStatus == 'Approved',
            'text-yellow-500': insuranceCriteriaStatus == 'Under Review',
            'text-red-500': insuranceCriteriaStatus == 'Declined',
          }"
          >{{ insuranceCriteriaStatus }}</span
        >
      </div>
      <div class="bg-gray-200 md:my-5 md:py-2 rounded-lg">
        <p
          class="text-center font-bold text-sm md:text-lg mx-auto"
          :class="{
            'text-green-600': insuranceCriteriaStatus == 'Approved',
            'text-lula': insuranceCriteriaStatus == 'Under Review',
            'text-red-600': insuranceCriteriaStatus == 'Declined',
          }"
          v-for="reason in ineligibilityReasons"
          :key="reason"
        >
          {{ reason }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const inputs = ref({
      product: "ContinuousCoverage",
      vin: "",
      state: "",
      mileage: "",
      telematics_installed: true,
      source: "Ashley Bot",
    });
    const ineligibilityReasons = ref([]);
    const insuranceCriteriaStatus = ref("");
    const disableSubmit = ref(false);
    const loadCounter = ref("");
    const checkEligibilityMessage = ref({
      content: "Check Eligibility",
      original: "Check Eligibility",
    });
    const mileageRangeOptions = computed(() => {
      return [
        { value: 24999, label: "Less than 25,000mi" },
        { value: 50000, label: "25,001mi - 50,000mi" },
        { value: 75000, label: "50,001mi - 75,000mi" },
        { value: 100000, label: "75,001mi - 100,000mi" },
        { value: 125000, label: "100,001mi - 125,000mi" },
        { value: 150000, label: "125,001mi - 150,000mi" },
        { value: 175000, label: "150,001mi - 175,000mi" },
        { value: 200000, label: "175,001mi - 200,000mi" },
        { value: 225000, label: "200,001mi - 225,000mi" },
        { value: 250000, label: "225,001mi - 250,000mi" },
        { value: 250001, label: "Greater than 250,000mi" },
      ];
    });
    const submitForm = async () => {
      disableSubmit.value = true;
      checkEligibilityMessage.value.content += "[[LOAD]]";
      ineligibilityReasons.value = [];
      insuranceCriteriaStatus.value = "";
      try {
        let response = await store.dispatch("getUnderwritingResponse", {
          inputs,
        });
        if (response.success == false) {
          ineligibilityReasons.value = [response.data.detail];
          insuranceCriteriaStatus.value = "Declined";
        } else if (response.data?.errors) {
          ineligibilityReasons.value = response.data.errors;
          insuranceCriteriaStatus.value = response.data.status;
        } else {
          ineligibilityReasons.value = ["No issues found"];
          insuranceCriteriaStatus.value = response.data.status;
        }
      } catch (e) {
        console.log(e);
        ineligibilityReasons.value = [
          "Failed to connect to GetAddify's underwriting service. Please try again.",
        ];
      }
      disableSubmit.value = false;
      checkEligibilityMessage.value.content =
        checkEligibilityMessage.value.original;
    };
    return {
      inputs,
      mileageRangeOptions,
      submitForm,
      disableSubmit,
      ineligibilityReasons,
      insuranceCriteriaStatus,
      checkEligibilityMessage: computed(() => {
        if (checkEligibilityMessage.value.content) {
          return {
            ...checkEligibilityMessage.value,
            content: (checkEligibilityMessage.value.content || "").replace(
              "[[LOAD]]",
              loadCounter.value,
            ),
          };
        }
        return checkEligibilityMessage.value;
      }),
      vehicleRegistrationStates: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
    };
  },
};
</script>
